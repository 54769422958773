(function () {
    'use strict';

    angular
        .module('salesflare.components.emailCompose', [])
        .component('sfEmailCompose', {
            controller,
            controllerAs: 'vm',
            templateUrl: 'app-ajs/components/emailcompose/emailcompose.html',
            bindings: {
                /* General attributes */
                onEmailFormDirtyChanged: '&',
                onEmailDataChanged: '&',
                onEmailFormValidityChanged: '&',
                savingDraft: '<', // When saving a draft less fields are required, changing this parameter could possibly trigger a change of the emailForm's validity
                type: '<', // 'reply', 'replyAll', 'forward', undefined or null
                /* initializing the editor */
                message: '<'
            }
        });

    function controller($q, $mdMedia, $scope, $timeout, $window, $compile, $element, datasources, utils, fileService, model, email, persons, joditService, draftsService) {

        const vm = this;
        const unsubscribeUrl = '<div><a style="color: #6d7684;" href="{{ unsubscribe_url }}" target="_blank" rel="noopener noreferrer">Unsubscribe from our emails</a></div>';
        // We also match the preceding br added by the editor to make sure we remove that as well
        const unsubscribeUrlDetectionRegex = /((<div><br><\/div>)|(<br>))?<div><a[^>]*?{{ unsubscribe_url }}[^>]*?>.*?<\/a><\/div>/gm;
        let viewHTML = false;
        let attachmentsContainer;
        let jodit;
        const maxAttachmentsSizes = {
            google: 25 * 1000 * 1000, // 25MB
            office365: 8 * 1000 *  1000, // 8MB // Set it to 8MB because of 150 megabytes (MB) upload limit in a 5 minute period which could cause issues for workflow emails (sending an email every 18s) (https://learn.microsoft.com/en-us/graph/throttling-limits#limits-per-app-id-and-mailbox-combination)
            smtp: 10 * 1000 * 1000, // 10MB
            individualFile: 10 * 1000 * 1000 // 10MB
        };
        let totalAttachmentsSize = 0;
        let thread = null;

        vm.$mdMedia = $mdMedia;
        vm.options = {
            includeUnsubscribeLink: false
        };

        vm.$onInit = async () => {

            if (vm.filter && !isFilterFromSelectedContacts(vm.filter)) {
                vm.filter = vm.filter || {};
            }

            await init();

            // When replying or replying to all, we want the editor to be focused on dialog opening
            jodit = joditService.getJoditEditor({
                queryStringToBindTo: $element.find('.editor')[0],
                autofocus: (vm.type !== 'reply' && vm.type !== 'replyAll') ? false : true,
                previewButton: {
                    enabled: false
                },
                mergeFieldButton: {
                    enabled: false
                },
                templatesButton: {
                    enabled: true,
                    onSelectTemplate: function (selectedTemplate, preventInsertTemplate) {

                        if (!preventInsertTemplate) {
                            // This code pastes the template into the editor
                            // The code is taken from https://github.com/xdan/jodit/blob/e722fad9277ac9e1421e954f508d7f4f8f0f1296/src/modules/Selection.ts#L563
                            // Some changes are made so it doesn't insert a newline after inserting the template and it puts the cursor at the correct position
                            const node = jodit.createInside.div();
                            const fragment = jodit.createInside.fragment();
                            let lastChild;

                            if (jodit.selection.isFocused() && jodit.isEditorMode()) {
                                jodit.selection.focus();
                            }

                            node.innerHTML = selectedTemplate.html_body;

                            lastChild = node.lastChild;

                            if (!lastChild) {
                                return;
                            }

                            while (node.firstChild) {
                                lastChild = node.firstChild;
                                fragment.append(node.firstChild);
                            }

                            jodit.selection.insertNode(fragment, false);
                            jodit.selection.setCursorIn(lastChild);
                        }

                        if (selectedTemplate.subject && !vm.message.subject) {
                            vm.message.subject = selectedTemplate.subject;
                        }
                    }
                },
                attachmentsButton: {
                    enabled: true,
                    onAttachments: vm.uploadAttachments
                },
                sendTestButton: {
                    enabled: false
                }
            },
            // On change sync body text with our model
            function (bodyText) {

                vm.message.body = bodyText;
            },
            // Sync mode changes with our model
            function (mode) {

                viewHTML = mode === 'html';
            });

            if (vm.message) {
                /*
                 * Adds the signature to the email body when:
                 *      creating a new (`!vm.message.body`) in-app email
                 *      replying (all)/forwarding an email from a data source (`vm.message.from`) with `add_signature_to_replies` to `true`
                 */
                if (!vm.message.body && vm.message.from && vm.message.from.email_signature) {
                    vm.message.body = ('<br><br><div class="sf-signature">' + vm.message.from.email_signature + '</div>');
                }
                else if (vm.message.body) {
                    const isReplyOrForward = (vm.type === 'reply' || vm.type === 'replyAll' || vm.type === 'forward');

                    if (isReplyOrForward) {
                        vm.message.body = ('<div class="sf-message">' + vm.message.body + '</div>');
                    }

                    if (vm.message.from && vm.message.from.add_signature_to_replies && vm.message.from.email_signature && isReplyOrForward) {
                        vm.message.body = ('<br><br><div class="sf-signature">' + vm.message.from.email_signature + '</div>' + vm.message.body);
                    }

                    // Never show the unsub url in the editor
                    vm.message.body = vm.message.body.replace(unsubscribeUrlDetectionRegex, '');
                }

                jodit.setEditorValue(vm.message.body || '');
            }

            // Make sure we append it after the source
            // Basically wait for Jodit to add the jodit_source
            $timeout(function () {

                // The container allows us to easily add the attachments in 1 place and apply some styling (like clear:both), check jodit.scss
                attachmentsContainer = $compile(''
                    + '<div class="jodit-attachments-container">'
                    + '    <md-chip ng-repeat="file in vm.message.attachments" title="{{ file.file_name }}">'
                    + '        <div layout="row" ng-click="vm.download(file.file_url)">'
                    + '            <md-progress-circular ng-if="!file.file_type" class="spinner" md-diameter="16" md-mode="indeterminate"></md-progress-circular>'
                    + '            <md-icon ng-if="file.file_type" class="icon icon-16 md-font material-icons" md-font-icon="{{ file.file_type | attachmentIcon }}"></md-icon>'
                    + '            <span class="accent ellipsis">{{ file.file_name }}</span>'
                    + '            <md-icon class="icon icon-18 md-font material-icons sf-icon-close" ng-click="vm.removeAttachment(file.id, file.file_size)"></md-icon>'
                    + '        </div>'
                    + '    </md-chip>'
                    + '</div>'
                )($scope);
                angular.element(jodit.container).find('.jodit-workplace').append(attachmentsContainer);
            });

            // When we click the 'Forward' menu option of an email, we want the 'To' field to be focused on open
            // To achieve this, we have to do some magic to select the correct input field in the form and to focus it
            if (vm.type === 'forward') {
                $timeout(function () {

                    const toField = angular.element.find('.to-field');
                    const input = toField[0].children[0].children[0];
                    input.focus();
                });
            }
        };

        $scope.$watch('vm.message', function (newVal, oldVal) {

            // The watch listener gets called with the same new and old val every time.
            // But we are only interested when the message has actually changed
            if (angular.equals(newVal, oldVal)) {
                return;
            }

            vm.customMessage = null;
            const isInvalid = vm.message && vm.message.from && totalAttachmentsSize > maxAttachmentsSizes[vm.message.from.type];

            if (isInvalid && $scope.emailForm.attachments.$valid) {
                const message = 'Total size of all attachments cannot be larger than ' + (maxAttachmentsSizes[vm.message.from.type] / 1000000) + 'MB';
                utils.showErrorToast(message);
                vm.customMessage = message;
            }

            $scope.emailForm.attachments.$setValidity('attachment size', !isInvalid);

            // If the from (data source) changes update the signature if needed
            if (jodit && oldVal.from && oldVal.from.email && (oldVal.from.email !== newVal.from.email)) {
                const dataSource = newVal.from;
                const addNewSignature = (
                    dataSource && dataSource.email_signature
                    && ((dataSource.add_signature_to_replies && ['reply', 'replyAll', 'forward'].includes(vm.type))
                        || !(['reply', 'replyAll', 'forward'].includes(vm.type)))
                );

                // If old signature -> remove
                const currentSignature = jodit.editor.querySelector('.sf-signature');
                if (currentSignature) {
                    jodit.editor.querySelector('.sf-signature').remove();
                    jodit.setEditorValue(); // Sync model
                }

                // If new sig
                //      if sf-message -> insertAdjacentHTML(beforebegin, sig) -- basically insert before sf-message
                //      else add to end
                if (addNewSignature) {
                    const sfMessage = jodit.editor.querySelector('.sf-message');
                    if (sfMessage) {
                        sfMessage.insertAdjacentHTML('beforebegin', '<div class="sf-signature">' + newVal.from.email_signature + '</div>');
                        jodit.setEditorValue(); // Sync model
                    }
                    else {
                        jodit.setEditorValue(jodit.getEditorValue() + '<div class="sf-signature">' + newVal.from.email_signature + '</div>');
                    }
                }
            }

            // When the message has changed the form is definitely dirty
            // This fixes the ng-quil-editor not setting the form to dirty
            $scope.emailForm.$setDirty();

            if (vm.onEmailDataChanged && vm.message) {
                const messageObject = getFormattedMessage();

                draftsService.save(messageObject);

                return vm.onEmailDataChanged({
                    $event: {
                        messageObject,
                        senderDataSource: vm.message.from
                    }
                });
            }
        }, true);

        $scope.$watch('emailForm.$dirty', function () {

            if (vm.onEmailFormDirtyChanged) {
                return vm.onEmailFormDirtyChanged({
                    $event: {
                        $dirty: $scope.emailForm.$dirty
                    }
                });
            }
        });

        $scope.$watch('emailForm.$valid', function () {

            if (vm.onEmailFormValidityChanged) {
                return vm.onEmailFormValidityChanged({
                    $event: {
                        $valid: $scope.emailForm.$valid
                    }
                });
            }
        });

        vm.preventEnterSubmit = function ($event) {

            if ($event.keyCode === 13) {
                $event.preventDefault();
                $event.stopPropagation();
            }
        };

        vm.showingHTML = function () {

            return viewHTML;
        };

        vm.onRecipientsChanged = function () {

            if (vm.onEmailDataChanged && vm.message) {
                const messageObject = getFormattedMessage();

                draftsService.save(messageObject);

                return vm.onEmailDataChanged({
                    $event: {
                        messageObject,
                        senderDataSource: vm.message.from
                    }
                });
            }
        };

        vm.onRecipientRemoved = (emailAddress) => {

            draftsService.delete(emailAddress);
        };

        vm.focusElement = function (id) {

            return angular.element('#' + id).focus();
        };

        vm.clickElement = function (id) {

            return angular.element('#' + id).click();
        };

        vm.uploadAttachments = function (files) {

            vm.showDragOverlay = false;

            if (!files || files.$error) {
                return;
            }

            vm.message.attachments = vm.message.attachments || [];

            files.forEach(function (file) {

                const displayFile = {
                    file_name: file.name,
                    file_size: file.size
                };

                if (file.size > Math.min(maxAttachmentsSizes.individualFile, maxAttachmentsSizes[vm.message.from.type])) {
                    const fileMessage = `A file cannot be larger than ${Math.min(maxAttachmentsSizes.individualFile, maxAttachmentsSizes[vm.message.from.type]) / 1000000}MB`;
                    return utils.showErrorToast(fileMessage);
                }

                if (totalAttachmentsSize + file.size > maxAttachmentsSizes[vm.message.from.type]) {
                    const message = 'Total file size cannot be larger than ' + (maxAttachmentsSizes[vm.message.from.type] / 1000000) + 'MB';
                    return utils.showErrorToast(message);
                }

                totalAttachmentsSize += file.size;

                vm.message.attachments.push(displayFile);

                return fileService.uploadFile(file, true, function (newFile) {

                    const index = vm.message.attachments.indexOf(displayFile);

                    if (!newFile) { // Ergo something went wrong
                        // if still in the list remove it
                        if (index !== -1) {
                            vm.message.attachments.splice(index, 1);
                        }

                        totalAttachmentsSize -= file.size;
                        return;
                    }

                    newFile.file_size = file.size;

                    if (newFile && index !== -1) {
                        vm.message.attachments[index] = newFile;
                    }
                });
            });

            // Scroll to bottom of the email
            return $timeout(function () {

                const element = angular.element(jodit.container).find('.jodit-workplace')[0];

                if (element) {
                    element.scrollTop = element.scrollHeight;
                }
            }, 100);
        };

        vm.removeAttachment = function (fileId, size) {

            vm.message.attachments = vm.message.attachments.filter(function (f) {

                return f.id !== fileId;
            });

            if (!vm.message.filesToDelete) {
                vm.message.filesToDelete = [];
            }

            vm.message.filesToDelete.push(fileId);
            totalAttachmentsSize = totalAttachmentsSize - size;
        };

        vm.download = function (downloadUrl) {

            $window.open(downloadUrl, '_blank', 'noopener');
        };

        vm.drag = function (isDragging, cl, event) {

            // Needs the sync apply to for it to actually show properly
            return $scope.$apply(function () {

                const files = event.dataTransfer && event.dataTransfer.items;

                if (!files) { // This can happen when you start dragging but then drag away of the page
                    vm.showDragOverlay = false;
                }
                else if (isDragging && files.length > 1) {
                    vm.showDragOverlay = true;
                }
                else {
                    vm.showDragOverlay = isDragging && !files[0].type.includes('image');
                }
            });
        };

        vm.searchPersons = function (queryString) {

            if (!queryString) {
                return [];
            }

            return $q(function (resolve) {

                $timeout.cancel(thread);
                thread = $timeout(function () {

                    return persons.get(queryString).then(function (response) {

                        const filteredPersons = response.data.filter(function (p) {

                            if (!p.email) {
                                return false;
                            }

                            const recipientEmails = [...(vm.message.to || []), ...(vm.message.cc || []), ...(vm.message.bcc || [])].map(function (recipient) {

                                return recipient.email;
                            });

                            return !recipientEmails.includes(p.email);
                        });

                        const results = filteredPersons.map((p) => {

                            return {
                                name: p.name,
                                email: p.email,
                                picture: p.picture
                            };
                        });

                        // Add `queryString` to the list of results if it is a valid email address and it isn't included in the person response
                        if (!results.map((r) => r.email).includes(queryString) && utils.validateEmail(queryString)) {
                            const avatarTileLetter = /[^a-zA-Z0-9]/.test(queryString[0].toLowerCase()) ? 'a' : queryString[0].toLowerCase();
                            results.push({ name: queryString, email: queryString, picture: `https://lib.salesflare.com/avatars/avatar_tile_${avatarTileLetter}_80.png` });
                        }

                        return resolve(results);
                    });
                }, 750);
            });
        };

        vm.transformChip = (personChip) => {

            // If it is an object, it's already a known chip
            if (angular.isObject(personChip)) {
                return {
                    ...personChip,
                    // Only show domains on existing contacts when the name isn't also the email address
                    domain: personChip.name === personChip.email ? undefined : personChip.email.split('@')[1]
                };
            }

            // Otherwise, personChip should be an email
            // Name is important here, it will show in the email client of the recipient
            return {
                name: personChip,
                email: personChip,
                picture: null
            };
        };

        vm.addRecipientType = function (type) {

            if (type === 'cc') {
                vm.showCc = true;
                vm.message.cc = [];

                $timeout(function () {

                    angular.element('.cc-input')[0].focus();
                });
            }

            if (type === 'bcc') {
                vm.showBcc = true;
                vm.message.bcc = [];

                $timeout(function () {

                    angular.element('.bcc-input')[0].focus();
                });
            }
        };

        /**
         * When used in a workflow step, when needed, will show an SMTP error message with a link to open dialog
         * When used elsewhere, will check if SMTP settings are needed and show the dialog right away.
         *
         * @returns {undefined}
         */
        vm.checkDataSourceSMTPSettings = function () {

            if (!vm.message || !vm.message.from || !vm.message.from.type) {
                return;
            }

            return email.showConfigureSMTPSettingsDialogIfNeeded(vm.message.from).then( function () {
                vm.hasInvalidSMTP = false;
            });

        };
        ////////////////////////

        async function init() {

            // Register a helper function for Handlebars, needed to parse our merge fields
            Handlebars.registerHelper('merge', handlebarsMergeHelper);

            const defaultMessage = {
                automatic: false,
                filter: vm.filter,
                tracked: true,
                includeUnsubscribeLink: true
            };

            if (!vm.message.body && vm.message.to?.length === 1) {
                const savedMessage = await draftsService.get(vm.message.to[0].email);
                vm.message = Object.assign({}, vm.message, savedMessage);
            }

            vm.message = Object.assign({}, defaultMessage, vm.message);
            vm.message.to = vm.message.to ? vm.message.to : [];
            vm.message.cc = vm.message.cc ? vm.message.cc : [];
            vm.message.bcc = vm.message.bcc ? vm.message.bcc : [];

            // Never show the unsub url in the editor`
            vm.message.body = vm.message.body && vm.message.body.replace(unsubscribeUrlDetectionRegex, '');
            vm.showCc = (vm.message.cc && vm.message.cc.length > 0);

            if (vm.message.attachments && vm.message.attachments.length > 0) {
                totalAttachmentsSize = vm.message.attachments.reduce(function sum(total, attachment) {

                    return total + attachment.file_size;
                }, 0);
            }

            try {
                await getEmailSources();
                vm.doneLoading = true;
            }
            catch {
                vm.doneLoading = true;
            }
        }

        function getEmailSources() {

            // Skip check when we get passed a from.
            // We assume we are editing and the from is already vetted
            const skipSMTPCheck = !!vm.message.from;

            return datasources.getEmailSources().then(function (response) {

                vm.datasources = [];

                vm.datasources = prepareDataSourcesForUse(response.data);

                // Handle array of emails that should be used as the sending email address, if included in the available aliases
                const dataSourceAliases = new Set(vm.datasources.map(function (dataSource) {

                    return dataSource.sendAs;
                }));

                if (angular.isArray(vm.message.potentialSenderEmails)) {
                    vm.message.from = vm.message.potentialSenderEmails.find(function (potentialDataSource) {

                        return dataSourceAliases.has(potentialDataSource.email);
                    });
                }

                vm.message.from = vm.message.from || response.data.find(function (dataSource) {

                    return dataSource.primary;
                }) || response.data[0];

                if (angular.isArray(vm.message.from) && vm.message.from.length > 0) {
                    vm.message.from = vm.message.from[0];
                }

                const fromInDataSources = vm.datasources.find(function (ds) {

                    return ds.sendAs.toLowerCase() === vm.message.from.email.toLowerCase();
                });

                if (!fromInDataSources && vm.message.from) {
                    vm.message.from.sendAs = vm.message.from.sendAs || vm.message.from.email;
                    vm.datasources.unshift(vm.message.from);
                }
                else {
                    vm.message.from = fromInDataSources;
                }

                if (skipSMTPCheck) {
                    return;
                }

                return email.showConfigureSMTPSettingsDialogIfNeeded(vm.message.from);
            });
        }

        /**
         * List all aliases for each data source (in the order they're returned from the API, else alphabetically), grouped per data source, with the primary data source first (and then in order of connecting them)
         * Order the aliases with the default alias first (in Gmail the default one set in Gmail, in Office 365 the main email address we connected with)
         *
         * @param {Array.<Object>} dataSources
         * @returns {Array.<Object>} preparedDataSources
         */
        function prepareDataSourcesForUse(dataSources) {

            let preparedDataSources = [];
            dataSources = dataSources.sort(compareDataSources);

            for (const i in dataSources) {
                const dataSource = dataSources[i];
                if (dataSource.aliases.length > 0) {
                    for (const j in dataSource.aliases) {
                        const aliasObject = dataSource.aliases[j];
                        const newDataSource = angular.copy(dataSource);
                        newDataSource.sendAs = aliasObject.email;
                        newDataSource.displayName = aliasObject.display_name;
                        preparedDataSources.push(newDataSource);
                    }
                }
            }

            // Dedupe when email addresses are shown twice
            //    *  1. default alias on one data source over non-default alias on another data source
            //    *  2. if the aliases are both non-default aliases, aliases on the primary data source have preference, or in general aliases higher up in the list specified in the first bullet point
            preparedDataSources = preparedDataSources.filter(function (dataSource, index, array) {

                const dupeDefaultAliasIndex = array.findIndex(function (ds) {

                    return ds.sendAs.toLowerCase() === dataSource.sendAs.toLowerCase() && ds.sendAs.toLowerCase() === ds.email.toLowerCase();
                });

                if (dupeDefaultAliasIndex !== -1) {
                    return index === dupeDefaultAliasIndex;
                }

                const dupeAliasIndex = array.findIndex(function (ds) {

                    return ds.sendAs.toLowerCase() === dataSource.sendAs.toLowerCase();
                });

                return index === dupeAliasIndex;
            });

            return preparedDataSources;
        }

        // Used for sorting the data sources received from the API
        function compareDataSources(dataSource1, dataSource2) {

            if (dataSource1.primary && !dataSource2.primary) {
                return -1;
            }

            if (!dataSource1.primary && dataSource2.primary) {
                return 1;
            }

            if (dataSource1.email < dataSource2.email) {
                return -1;
            }

            if (dataSource1.email < dataSource2.email) {
                return 1;
            }

            return 0;
        }

        function getFormattedMessage() {

            // Default the alias name only to the model name when from is from the session user
            const aliasName = vm.message.from.displayName || (vm.message.from.id ? model.me.name : '');
            const message = {
                from: {
                    email: vm.message.from && vm.message.from.sendAs,
                    name: aliasName
                },
                to: vm.message.to ? vm.message.to.map(stripExcessContactProperties) : [],
                cc: vm.message.cc ? vm.message.cc.map(stripExcessContactProperties) : [],
                bcc: vm.message.bcc ? vm.message.bcc.map(stripExcessContactProperties) : [],
                thread_id: vm.message.thread_id,
                in_reply_to: vm.message.in_reply_to,
                tracked: vm.message.tracked
            };

            if (vm.message.body !== null) {
                message.body = vm.message.body;
            }

            if (vm.message.includeUnsubscribeLink && vm.message.body && !vm.message.body.includes('{{ unsubscribe_url }}')) {
                message.body += '<br>' + unsubscribeUrl;
            }

            if (vm.message.subject) {
                message.subject = vm.message.subject;
            }

            if (vm.message.filesToDelete) {
                message.filesToDelete = vm.message.filesToDelete;
            }

            if (vm.message.attachments) {
                message.attachments = vm.message.attachments.map(function (attachment) {

                    delete attachment.size;

                    return attachment;
                });
            }
            else {
                message.attachments = [];
            }

            message.data_source = vm.message.from && vm.message.from.id;

            return message;
        }

        function stripExcessContactProperties(contact) {

            return {
                name: contact.name,
                email: contact.email
            };
        }

        function handlebarsMergeHelper(text, options) {

            // If we are handling files we don't need to escape since we want to put in the link tag
            if (angular.isArray(text) && options.data.root.files.length > 0) {
                const isFiles = text.some(function (t) {

                    return options.data.root.files.includes(t);
                });

                if (isFiles) {
                    text = text.join(', ').replace(/, ([^,]*)$/, ' & $1');
                }
            }

            if (options.hash.fallback === 'REPLACE_THIS') {
                options.hash.fallback = '';
            }

            return new Handlebars.SafeString(text || options.hash.fallback);
        }

        function isFilterFromSelectedContacts(filter) {

            return filter && filter.rules && filter.rules.length === 1 && filter.rules[0].id === 'contact.id';
        }
    }
})();
