const SfxAuthenticationCodeDialog = require('@feature/login/components/authentication-code-dialog/authentication-code-dialog.component').SfxAuthenticationCodeDialog;
const SfxEnterRecoveryCodeDialog = require('@feature/login/components/enter-recovery-code-dialog/enter-recovery-code-dialog.component').SfxEnterRecoveryCodeDialog;

(function () {
    'use strict';

    angular
        .module('salesflare')
        .controller('LogInController', logInController);

    function logInController(matDialogService, zoneService, $mdDialog, $scope, $state, $window, $exceptionHandler, helperFunctionsService, model, authentication, utils, config, datasources) {

        $scope.oniOS = ($window.device && $window.device.platform === 'iOS');
        $scope.disableButtons = false;
        $scope.copyRightYear = new Date().getFullYear();
        $scope.inOutlook = config.mode === 'outlook-web';

        const connectInfo = {
            google: {}
        };

        init();

        $scope.logIn = function logIn(username, password) {

            $scope.disableButtons = true;

            $scope.loginForm.password.$warning = {};
            $scope.loginForm.password.$setValidity('general', true);
            $scope.loginForm.email.$setValidity('general', true);
            $scope.emailErrorText = undefined;
            $scope.passwordErrorText = undefined;
            $scope.passwordWarningText = undefined;

            if (!!username && !!password) {

                // Mobile doesn't get loaded over http, so it can't be accepted in the google reCAPTCHA settings
                if ($window.isMobile) {
                    return authenticate(username, password, null);
                }

                return $window.grecaptcha.ready(function () {

                    return $window.grecaptcha.execute('6LeIk8EUAAAAAIKkocBie39wYk-ril7DzO5AbUvK', { action: 'login' }).then(function (captchaToken) {

                        return authenticate(username, password, captchaToken);
                    }, function (err) {

                        return $scope.$applyAsync(function () {

                            $scope.disableButtons = false;

                            if (err) {
                                $exceptionHandler(err);
                            }
                        });
                    });
                }, function (error) {

                    return $scope.$applyAsync(function () {

                        $scope.disableButtons = false;

                        if (error) {
                            $exceptionHandler(error);
                        }
                    });
                });
            }

            $scope.disableButtons = false;
        };

        function authenticate(username, password, captchaToken) {

            if ($window.isMobile) {
                captchaToken = null;
            }

            return authentication.logIn(username, password, captchaToken)
                .then(function (response) {

                    return setTokenAndRedirectAfterLogin(response.data.token);
                })
                .catch(function (err) {

                    $scope.disableButtons = false;

                    if (!err.data) {
                        return;
                    }

                    if (err.data.message === 'User not found' || err.data.message === 'There is no Salesflare user associated with this email') {
                        $scope.loginForm.email.$setValidity('general', false);
                        $scope.emailErrorText = err.data.message;
                    }
                    else if (err.data.message.includes('You have no Salesflare password set')) {
                        $scope.loginForm.password.$warning.general = true;
                        $scope.passwordWarningText = err.data.message;
                    }
                    else if (err.data.message.toLowerCase().includes('two-factor authentication is required')) {

                        zoneService.run(() => {
                            const authenticationDialogRef = matDialogService.open(SfxAuthenticationCodeDialog, {
                                panelClass: ['sfx-dialog-container', 'sfx-authentication-code-dialog-container'],
                                data: {
                                    email: username,
                                    password,
                                    captchaToken
                                }
                            });

                            authenticationDialogRef.afterClosed().subscribe((authenticationDialogResult) => {

                                if (authenticationDialogResult?.token) {
                                    return setTokenAndRedirectAfterLogin(authenticationDialogResult.token);
                                }

                                if (authenticationDialogResult?.action === 'SHOW_RECOVERY_CODE_DIALOG') {
                                    const recoveryCodeDialogRef = matDialogService.open(SfxEnterRecoveryCodeDialog, {
                                        panelClass: ['sfx-dialog-container', 'sfx-enter-recovery-code-dialog-container'],
                                        data: {
                                            email: username,
                                            password,
                                            captchaToken
                                        }
                                    });

                                    recoveryCodeDialogRef.afterClosed().subscribe((recoveryDialogResult) => {

                                        if (recoveryDialogResult?.token) {
                                            return setTokenAndRedirectAfterLogin(recoveryDialogResult.token);
                                        }
                                    });
                                }
                            });
                        });
                    }
                    else if (err.data.statusCode === 429) {
                        $scope.loginForm.password.$setValidity('general', false);
                        $scope.passwordErrorText = 'You have exceeded the login attempt limit, try again in a minute.';
                    }
                    else {
                        $scope.loginForm.password.$setValidity('general', false);
                        $scope.passwordErrorText = err.data.message;
                    }
                });
        }

        $scope.logInGoogle = function () {

            $scope.disableButtons = true;

            if (!$window.isMobile) {
                return showAuthenticationPopUp(`${config.apiUrl}datasources/connect?type=google&is_sign_in=true`).then(connectCallback);
            }

            return $window.plugins.googleplus.login(connectInfo.google, function (googleAuthData) {

                googleAuthData.is_sign_in = true;

                const tradePromise = datasources.trade(googleAuthData, { type: 'google' }, { noToast: true, noToastForStatusCode: [403] });

                tradePromise
                    .then(function (response) {

                        return connectCallback(response.data);
                    })
                    .catch(function (err) {

                        if (err?.data?.message === 'UNAUTHORIZED_SCOPES') {
                            $scope.disableButtons = false;

                            const insufficientPermissionsDialog = $mdDialog.confirm({ multiple: true })
                                .clickOutsideToClose(true)
                                .escapeToClose(true)
                                .title('Please enable the necessary permissions')
                                .textContent('Salesflare needs all these permissions to be able to automate your CRM work.')
                                .ok('Enable')
                                .cancel('cancel');

                            return $mdDialog.show(insufficientPermissionsDialog)
                                .then(() => {
                                    $scope.connectGoogle();
                                });
                        }

                        $exceptionHandler(err);
                        $scope.disableButtons = false;
                    });

                tradePromise.catch(function (err) {

                    $exceptionHandler(err);
                    $scope.disableButtons = false;

                    return $window.plugins.googleplus.logout(function (msg) {

                        if (msg !== 'Logged user out' && msg !== 'logged out') {
                            $exceptionHandler(msg);
                            return utils.showErrorToast('Oops! Something went wrong. Please try again.');
                        }
                    });
                });
            }, function (msg) {

                // Apply since not in angular digest loop here and we do want to update the view
                $scope.$apply(function () {

                    $scope.disableButtons = false;
                });

                // 12501 is user canceled the oauth flow
                if (msg && msg !== 12501) {
                    $exceptionHandler(msg);
                    utils.showErrorToast('Oops! Something went wrong. Please try again.');
                }

                $window.plugins.googleplus.logout(function (message) {

                    if (message !== 'Logged user out') {
                        $exceptionHandler(message);
                        return utils.showErrorToast('Oops! Something went wrong. Please try again.');
                    }
                });
            });
        };

        $scope.logInMicrosoft = function () {

            $scope.disableButtons = true;

            return showAuthenticationPopUp(`${config.apiUrl}datasources/connect?type=office365&is_sign_in=true`).then(connectCallback).catch(function () {

                $scope.disableButtons = false;
            });
        };

        function showAuthenticationPopUp(url) {

            if (config.mode === 'outlook-web') {
                return helperFunctionsService.handleOutlookPluginOAuthFlow(url);
            }

            return utils.popup(url);
        }

        function init() {

            if (model.isLoggedIn) {
                return $state.go('tasks.today');
            }

            if ($window.isMobile) {
                datasources.getConnectInfo('google').then(function (response) {

                    connectInfo.google = response.data;
                });
            }
        }

        function connectCallback(queryParams) {

            if (!queryParams) {
                $scope.disableButtons = false;

                return $exceptionHandler(new Error('Lost connection to the popup window or it was manually closed'));
            }

            if (queryParams.get('success') !== 'true') {
                $scope.disableButtons = false;

                return utils.showErrorToast(queryParams.get('error'));
            }

            return setTokenAndRedirectAfterLogin(queryParams.get('token'));
        }

        function setTokenAndRedirectAfterLogin(token) {

            model.setToken(token);

            return $state.go('tasks.today');
        }
    }
})();
