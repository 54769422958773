// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    staging: true,
    development: false,
    env: 'staging',
    mode: '',
    apiUrl: 'https://stagingapi.salesflare.com/',
    notificationsUrl: 'https://pubsub-staging.salesflare.com:443',
    // The referUrl config param is not set on development since fallback uses the app's signup page
    referUrl: null,
    segment: {
        key: 'wsOWfdMCSewOj4qTYzQ7v4CuvWxBoHjf'
    },
    growsumo: {
        key: 'pk_TWoBWquDGJdZM1DDjTkzeomcp9qd5GzV'
    },
    refiner: {
        key: '38d29720-dc72-11ea-8588-91da2dac4ccb'
    },
    gcm: 128469695999,
    debugInfoEnabled: false,
    stripe: {
        key: 'pk_test_dQe0SoojMpwj2oxWhxiSW2vR',
        plans: {
            monthly_GROWTH_USD: 'price_1HuaUID7dHLLR45xcloRlMCa',
            monthly_GROWTH_EUR: 'price_1HuaUID7dHLLR45x63pGBrrv',
            annually_GROWTH_USD: 'price_1HuaUID7dHLLR45xK24aLIuD',
            annually_GROWTH_EUR: 'price_1HuaUID7dHLLR45xlYxiM4sX',
            monthly_PRO_USD: 'monthly_PRO_USD',
            monthly_PRO_EUR: 'monthly_PRO_EUR',
            annually_PRO_USD: 'annually_PRO_USD',
            annually_PRO_EUR: 'annually_PRO_EUR'
        }
    },
    intercom: {
        appId: 'llin3h2f',
        horizontal_padding: 110,
        alignment: 'right'
    },
    zapier: {
        clientId: 'iqBeMJF5oFzkEFilfacD4Evy7oexnNneVQGNQfR7'
    },
    ycbm: {
        videoCallUrl: 'https://salesflare-video-call.youcanbook.me?noframe=true&skipHeaderFooter=true',
        phoneCallUrl: 'https://salesflare-quick-call.youcanbook.me?noframe=true&skipHeaderFooter=true'
    },
    sentry: {
        enabled: true
    },
    tracking: {
        baseUnsubscribeUrl: 'https://stagingapi.salesflare.com/optout',
        imgUrl: 'https://stagingpixel.salesfla.re/img/',
        oldImgUrl: 'https://stagingapi.salesflare.com/img/'
    },
    linkedIn: {
        redirectUrl: 'https://stagingapi.salesflare.com/datasources/connect?type=linkedin', // When changing this, it also needs to be changed in the LinkedIn developer console and the server
        oauthUrl: 'https://www.linkedin.com/oauth/v2/authorization',
        clientId: '78gm3u87vvyi9l'
    }
};
